<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12 cardGraph">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 cardGraph">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer2" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 cardGraph">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer3" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content">
                <div class="modal-body">
                    <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                    </v-data-table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Project Management',
                disabled: false,
                href: '#',
                },
                {
                text: 'Project Dashboard',
                disabled: true,
                href: '#',
                },
            ],
            itemDetailLists: [],
            headersDetails: [
                { text: 'DATE', value: 'dt_modified', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROJECT', value: 'pr_name', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'STAGE', value: 'stgDesc', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TYPE', value: 'pr_jenis', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CATEGORY', value: 'catDesc', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SURVEY', value: 'survey_date', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'LOCATION', value: 'pr_loc', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PROVINCE', value: 'propinsi', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'STAT', value: 'stat', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatableDetail: false,
            searchItemDetail: '',
            userGroup: null,
            appl_id: 'ANALYSIS'
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{

        initialize(){

            this.$store.dispatch('setOverlay', true)
            axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectDashboard`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.renderChart(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3)

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },
        
        renderChart(data1, data2, data3 ){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                title:{
                    text: "Total Project by Office (Click bar to see details)"
                },
                axisX:{
                    interval: 1
                },
                data: [{
                    type: "column",
                    indexLabel: "{y}",
                    click: this.getDetails1,
                    indexLabelPlacement: "outside",  
                    indexLabelOrientation: "horizontal",
                    dataPoints: data1,
                }]
            })

            chart1.render()

            var chart2 = new CanvasJS.Chart("chartContainer2", {
                animationEnabled: true,
                theme: "light2",
                title:{
                    text: "Total Project by Type"
                },
                data: [{
                    type: "column",
                    indexLabel: "{y}",
                    click: this.getDetails2,
                    indexLabelPlacement: "outside",  
                    indexLabelOrientation: "horizontal",
                    dataPoints: data2,
                }]
            })

            chart2.render()

            var chart3 = new CanvasJS.Chart("chartContainer3", {
                animationEnabled: true,
                theme: "light2",
                title:{
                    text: "Total Project by Category"
                },
                data: [{
                    type: "column",
                    indexLabel: "{y}",
                    click: this.getDetails3,
                    indexLabelPlacement: "outside",  
                    indexLabelOrientation: "horizontal",
                    dataPoints: data3,
                }]
            })

            chart3.render()

        },

        async getDetails1(e){

            $('#detailModal').modal('show')

            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectDashboard/getDetails1`, { 
                    office: e.dataPoint.label
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                this.itemDetailLists = res.data.result
                this.loadingDatatableDetail = false
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async getDetails2(e){

            $('#detailModal').modal('show')

            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectDashboard/getDetails2`, { 
                    type: e.dataPoint.label
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                this.itemDetailLists = res.data.result
                this.loadingDatatableDetail = false
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async getDetails3(e){

            $('#detailModal').modal('show')

            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectDashboard/getDetails3`, { 
                    category: e.dataPoint.label
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                this.itemDetailLists = res.data.result
                this.loadingDatatableDetail = false
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })


        },

    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>